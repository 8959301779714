<template>
  <div :class="expectColor" v-if="!isExplorer">
    <span :title="row['sourceIP']">{{ row['direction'] }}</span>
  </div>
  <ExplorerLink v-else :explorer-name="explorerName"/>
</template>
<script>
import {getExpectColor} from '@/js/helper.js';
import ExplorerLink from '@/components/dynamic/ExplorerLink';

export default {
  components: {ExplorerLink},
  props: {
    row: Object,
  },
  computed: {
    expectColor() {
      return getExpectColor(this.row);
    },
    isExplorer() {
      return this.row['direction'].includes('Explorer');
    },
    explorerName() {
      return this.row['direction'].match(/(Explorer:\s)(.+)/)[2];
    },
  },
};
</script>